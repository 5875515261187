<template>
<div>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text class="navi-item">
        <a @click="modal_no" class="navi-link"> Novo Nivel </a>
      </b-dropdown-text>

      <b-dropdown-text
        tag="div"
        class="navi-separator opacity-70"
      ></b-dropdown-text>

      <b-dropdown-text class="navi-item">
        <a class="navi-link"> Editar </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>

    <!-- modal para criar o primerio nó -->
    <div>
      <b-modal v-model="modalShow" title="Criar ">
        <b-form-group>
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="input-group-1"
                label="Tipo de no:"
                label-for="input-1"
              >
                <select v-model="form.tipo" class="form-control" >
                  <option value="mensagem">Mensagem</option>
                  <option value="menu">Menu</option>
                  <option value="redirecionamento">Redirecionamento</option>
                 
                </select>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                id="input-group-1"
                label="Mensagem:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.mensagem"
                  type="text"
                  placeholder="Escreva aqui a mensagem para o usuario"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                id="input-group-1"
                label="Numero:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.opcao"
                  type="number"
                  placeholder="Escreva aqui a mensagem para o usuario"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-form-group>

        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="adicionar_no()">
            Adicionar
          </b-button>
       
        </template>
      </b-modal>
    </div>
</div>
</template>


<script>
export default {
  name: "menu_nos",
  props: { no: "" },
  components: {},
  mounted() {},
  data(){
    return {
      modalShow:false,
      form:{
        mensagem:'',
        tipo:'',
        opcao:'',
      }
    }
  },
  computed: {
    lista_nos() {
      return this.$store.state.chatbots.bot.nos;
    },
  },
  methods: {
    async adicionar_no() {
      var u = this.get_ultima_sequecia();
      var opcao = null;
        if(this.no.tipo == "menu"){
          opcao = this.form.opcao
        }
      var a = {
        id: null,
        sequencia: u + 1,
        mensagem: this.form.mensagem,
        tipo: this.form.tipo,
        ultimo: true,
        opcao: parseInt(opcao),
        pai: {
          id: null,
          sequencia: this.no.sequencia,
        },
        departamento: {
          id: null,
        },
        filhos: [],
      };

      await this.$store.dispatch("chatbots/adicionar_no", a);
       console.log(a);
    },
    async remover_no() {
      await this.$store.dispatch("chatbots/remover_no", this.no);
      console.log(a);
    },
    get_ultima_sequecia() {
      return this.lista_nos[this.lista_nos.length - 1].sequencia;
    },
    async modal_no() {
      this.modalShow = true;
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

