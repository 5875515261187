<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h5 class="mb-0 text-left">Editar chatbot</h5>
          <div hover class="mb-0 text-right">
            <b-button pill @click="modal_no" variant="primary"
              >Novo Nivel</b-button
            >
          </div>
        </div>

        <div class="card-body">
          <div
            class=""
            v-for="(lista1, index1) in this.array_final"
            :key="index1"
          >
            <div
              :style="'width: ' + lista1.porcentagem + '%'"
              class="border d-flex align-items-center p-4 float-right"
            >
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <a
                  class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                >
                  {{ lista1.tipo }}
                </a>
                <span class="text-muted font-weight-bold">
                  {{ lista1.mensagem }}
                </span>
              </div>
              <!--end::Text-->
              <!--begin::Dropdown-->
              <Menunos :no="lista1"></Menunos>
              <!--end::Dropdown-->
            </div>

            <div
              class=""
              v-for="(lista2, index2) in lista1.filhos"
              :key="index2"
            >
              <div
                :style="'width: ' + lista2.porcentagem + '%'"
                class="border d-flex align-items-center p-4 float-right"
              >
                <!--begin::Text-->
                <div class="d-flex flex-column flex-grow-1">
                  <a
                    class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                  >
                    {{ lista2.tipo }}
                  </a>
                  <span class="text-muted font-weight-bold">
                    {{ lista2.mensagem }}
                  </span>
                </div>
                <!--end::Text-->
                <!--begin::Dropdown-->
                <Menunos :no="lista2"></Menunos>
                <!--end::Dropdown-->
              </div>

              <div
                class=""
                v-for="(lista3, index3) in lista2.filhos"
                :key="index3"
              >
                <div
                  :style="'width: ' + lista3.porcentagem + '%'"
                  class="border d-flex align-items-center p-4 float-right"
                >
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1">
                    <a
                      class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                    >
                      {{ lista3.tipo }}
                    </a>
                    <span class="text-muted font-weight-bold">
                      {{ lista3.mensagem }}
                    </span>
                  </div>
                  <!--end::Text-->
                  <!--begin::Dropdown-->
                  <Menunos :no="lista3"></Menunos>
                  <!--end::Dropdown-->
                </div>

                <div
                  class=""
                  v-for="(lista4, index4) in lista3.filhos"
                  :key="index4"
                >
                  <div
                    :style="'width: ' + lista4.porcentagem + '%'"
                    class="border d-flex align-items-center p-4 float-right"
                  >
                    <!--begin::Text-->
                    <div class="d-flex flex-column flex-grow-1">
                      <a
                        class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                      >
                        {{ lista4.tipo }}
                      </a>
                      <span class="text-muted font-weight-bold">
                        {{ lista4.mensagem }}
                      </span>
                    </div>
                    <!--end::Text-->
                    <!--begin::Dropdown-->
                    <Menunos :no="lista4"></Menunos>
                    <!--end::Dropdown-->
                  </div>

                  <div
                    class=""
                    v-for="(lista5, index5) in lista4.filhos"
                    :key="index5"
                  >
                    <div
                      :style="'width: ' + lista5.porcentagem + '%'"
                      class="border d-flex align-items-center p-4 float-right"
                    >
                      <!--begin::Text-->
                      <div class="d-flex flex-column flex-grow-1">
                        <a
                          class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                        >
                          {{ lista5.tipo }}
                        </a>
                        <span class="text-muted font-weight-bold">
                          {{ lista5.mensagem }}
                        </span>
                      </div>
                      <!--end::Text-->
                      <!--begin::Dropdown-->
                      <Menunos :no="lista5"></Menunos>
                      <!--end::Dropdown-->
                    </div>

                    <div
                      class=""
                      v-for="(lista6, index6) in lista5.filhos"
                      :key="index6"
                    >
                      <div
                        :style="'width: ' + lista6.porcentagem + '%'"
                        class="border d-flex align-items-center p-4 float-right"
                      >
                        <!--begin::Text-->
                        <div class="d-flex flex-column flex-grow-1">
                          <a
                            class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                          >
                            {{ lista6.tipo }}
                          </a>
                          <span class="text-muted font-weight-bold">
                            {{ lista6.mensagem }}
                          </span>
                        </div>
                        <!--end::Text-->
                        <!--begin::Dropdown-->
                        <Menunos :no="lista6"></Menunos>
                        <!--end::Dropdown-->
                      </div>

                      <div
                        class=""
                        v-for="(lista7, index7) in lista6.filhos"
                        :key="index7"
                      >
                        <div
                          :style="'width: ' + lista7.porcentagem + '%'"
                          class="border d-flex align-items-center p-4 float-right"
                        >
                          <!--begin::Text-->
                          <div class="d-flex flex-column flex-grow-1">
                            <a
                              class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                            >
                              {{ lista7.tipo }}
                            </a>
                            <span class="text-muted font-weight-bold">
                              {{ lista7.mensagem }}
                            </span>
                          </div>
                          <!--end::Text-->
                          <!--begin::Dropdown-->
                          <Menunos :no="lista7"></Menunos>
                          <!--end::Dropdown-->
                        </div>
                        <div
                          class=""
                          v-for="(lista8, index8) in lista7.filhos"
                          :key="index8"
                        >
                          <div
                            :style="'width: ' + lista8.porcentagem + '%'"
                            class="border d-flex align-items-center p-4 float-right"
                          >
                            <!--begin::Text-->
                            <div class="d-flex flex-column flex-grow-1">
                              <a
                                class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
                              >
                                {{ lista8.tipo }}
                              </a>
                              <span class="text-muted font-weight-bold">
                                {{ lista8.mensagem }}
                              </span>
                            </div>
                            <!--end::Text-->
                            <!--begin::Dropdown-->
                            <Menunos :no="lista8"></Menunos>
                            <!--end::Dropdown-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div hover class="m-10 text-right">
          <b-button @click="salvar_bot()" pill variant="primary"
            >Salvar</b-button
          >
        </div>
      </div>
    </div>

    <!-- modal para criar o primerio nó -->
    <div>
      <b-modal v-model="modalShow" title="Criar ">
        <b-form-group>
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="input-group-1"
                label="Tipo de no:"
                label-for="input-1"
              >
                <select v-model="form.tipo" class="form-control" >
                  <option value="mensagem">Mensagem</option>
                </select>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                id="input-group-1"
                label="Mensagem:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.mensagem"
                  type="text"
                  placeholder="Escreva aqui a mensagem para o usuario"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-form-group>

        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="adicionar_primeiro_no()">
            Adicionar
          </b-button>
       
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import Menunos from "./menu_nos";

export default {
  name: "editarChatbot",

  props: { nos: "" },

  mixins: [fireAlert],
  components: {
    Menunos,
  },

  data() {
    return {
      modalShow: false,
      novo_no: [],
      variavel_no: "",
      array_no: [],
      form: {
        nome: "",
      },
      verif: false,
    };
  },

  created() {
    this.carregar_array(this.$store.state.chatbots.bot.nos);
  },
  watch: {
    array_nos() {
      console.log(this.$store.state.chatbots.bot);
      this.carregar_array(this.$store.state.chatbots.bot.nos);
    },
  },
  computed: {
    array_final() {
      return this.array_no;
    },
    array_nos() {
      return this.$store.state.chatbots.bot.nos;
    },
    bot() {
      return this.$store.state.chatbots.bot;
    },

    mensagem_alert() {
      return this.$store.state.empresas.mensagem_alert;
    },
  },

  methods: {
    carregar_array(value) {
      // console.log('valor')
      //  console.log(value)
      value.filter((no) => {
        if (no.sequencia == 1) {
          no.porcentagem = 100;
          this.$set(this.novo_no, no.sequencia - 1, no);

          this.pegar_filhos(this.novo_no);
        }
      });
    },
    async pegar_filhos(nos) {
      // console.log(nos)
      for (var c in nos) {
        for (var i in nos[c].filhos) {
          var fi = await this.pegar_no_filhos(nos[c].filhos[i]);
          fi.porcentagem = nos[c].porcentagem - 5;
          nos[c].filhos[i] = fi;
        }
        await this.pegar_filhos(nos[c].filhos);
      }
      // console.log(nos)

      this.array_no = nos;
      return nos;
    },

    pegar_no_filhos(value) {
      var a;
      this.bot.nos.filter((res) => {
        if (res.sequencia == value.sequencia) {
          a = res;
        }
      });
      return a;
    },

    async salvar_bot() {
      //  console.log(this.bot)
      await this.$store.dispatch("chatbots/update", this.bot);
    },

    async adicionar_primeiro_no() {
     
      var a = {
         "sequencia":1,
         "mensagem":this.form.mensagem,
         "tipo":this.form.tipo,
         "filhos":[
           
         ],
         "opcao":null,
         "pai":{
            "sequencia":0
         },
         "departamento":{
            "id":null
         },
         "ultimo":true
      }

      await this.$store.dispatch("chatbots/adicionar_no", a);
      // await this.$store.dispatch("chatbots/get", this.bot);

        this.modalShow = false;
    },

    async modal_no() {
      this.modalShow = true;
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>